
.nav-accordian .accordion {
    border: none !important;
    border-radius: 2px;
    padding: 0px 20px !important;
}

.nav-accordian .accordion__panel,
.nav-accordian  .accordion__button,
.nav-accordian  .accordion__button {
    background-color: white !important;
}

.nav-accordian .accordion__button {
    position: relative !important;
    padding: 16px 0px !important;
}

.nav-accordian .accordion__button:before {
    position: absolute !important;
    right: 70% !important;
    top: 23px !important;
    color: #e3e3e3 !important;
}

.nav-accordian {
    padding: 9px;
    background: white;
    height: calc(95vh - 5rem);
    padding-bottom: 2rem;
    overflow-y: auto;

}
.accordion__item:nth-child(1) .mobile_arrow  .accordion__button:before {
    display: inline-block !important;
    content: '' !important;
    height: 10px !important;
    width: 10px I !important;
    margin-right: 12px !important;
    border-bottom: 2px solid currentColor !important;
    border-right: 2px solid currentColor !important;
    transform: rotate(-45deg)  !important;

}
.nav-accordian .accordion__item {
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.139); */
}
.nav-accordian .accordion__item + .accordion__item{
    border-top: 0px  !important;
}
.accordion__item .mobile_arrow  .accordion__button:before{display: none;}